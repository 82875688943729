<template>
  <svg
    class="chevron"
    :class="'chevron-' + iconDirection"
    :height="iconSize"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
  <path
  d="M20,12c0,-0.316 -0.115,-0.603 -0.359,-0.846l-8.866,-8.81c-0.215,-0.229 -0.502,-0.344 -0.832,-0.344c-0.675,-0 -1.191,0.502 -1.191,1.176c-0,0.316 0.143,0.617 0.344,0.847l8.02,7.977l-8.02,7.977c-0.201,0.23 -0.344,0.517 -0.344,0.847c-0,0.674 0.516,1.176 1.191,1.176c0.33,-0 0.617,-0.115 0.832,-0.344l8.866,-8.81c0.244,-0.243 0.359,-0.53 0.359,-0.846Z"
  style="fill-rule:nonzero;"
  :class="'itp-' + iconColor"
  />
  </svg>
</template>

<script>
export default {
  props: {
    iconColor: {
      type: String,
      default: "grey",
    },
    iconSize: {
      type: Number,
      default: 24,
    },
    iconDirection: {
      type: String,
      default: "right",
    },
  },
};
</script>

<style lang="scss" scoped>

.chevron {
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  -ms-transition: -ms-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;

  &-right {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }

  &-down {
    transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
  }

  &-left {
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
  }

  &-up {
    transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
  }
}

.active {
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  -ms-transition: -ms-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  opacity: 0.8;
}

.itp-grey {
  fill: #5a5a5a;
}

.itp-black {
  fill: #1a1919;
}

.itp-lightgrey {
  fill: #8b8b8b;
}

.itp-red {
    fill: #F94141;
}

.itp-destructive {
  fill: var(--color-destructive);
}
</style>